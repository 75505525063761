import React, { useEffect, useState } from 'react'
import Sidebarcontent from '../sidebar/Sidebar'
import Footer from "../mainfooter/footerblack.jsx";
import Navbar from '../header/header.component';
import Lockcards from '../lockcard/Lockcards.jsx';
import axios from 'axios';
import { switchUrls } from '../../api/index.js';
import { Box, Card, CardContent, CardMedia, IconButton, Link } from '@mui/material';
import Inputfield from '../ui/Inputfield.jsx';
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useParams } from "react-router-dom";
const baseURL = switchUrls("bag");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");


function Lockmanagement() {
  const {tag_number} = useParams()
   const [loading,setloading] = useState(false)
    const[locklogs,setlocklogs] = useState([])
    const [displaylock,setdisplaylock] = useState({
      main_lock : false,
      lock_1 : false,
      lock_2 : false,
      lock_3 : false
    })
    const getallLogs = async ()=>{
        try {
          setloading(true)
            const {data} = await axios.get(`${baseURL}/api/getlocklogs/${tag_number}`,{
              headers : { Authorization:usertoken}
            })
            setlocklogs(data?.details)
            setloading(false)
            
        } catch (error) {
           setloading(false)
        }
    }
    const handelview = (field)=>{
      setdisplaylock((prev)=>{
        return {
          ...prev,
          [field] : !prev[field]
        }
      })
    }
    useEffect(()=>{
      getallLogs()
    },[])
  return (
    <>
  {
    loading ? <>
     <div className="loader-container">
                            <div className="loader">
                              <img
                                src={
                                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                                }
                                alt="Loading..."
                                loading="lazy"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          </div>
    </> : <>
      <Navbar/>
      <div className='editPetDetails-main-wrapper' style={{backgroundColor:"white"}}>
      <div className="bagActivation-left-wrapper">
            <Sidebarcontent />
          </div>
          <div style={{display:"flex",flexDirection:"column",minHeight:"100vh"}}>
           <div style={{textAlign:"center",margin:"1rem",marginTop:"2rem"}}>
           <p>
                      <strong>
                       Bag Lock History
                      </strong>
                    </p>
                {
                  locklogs?.length ? <>
                      <div style={{textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",}}>
                      <Card sx={{ display: 'flex',alignItems:"center",justifyContent:"center" }}>
                         <CardMedia
        component="img"
        sx={{ width: 90, margin: 1 }}
        image="https://storage.googleapis.com/pettag/qr/assets/bag-qr/bag-lock1.png"
        alt="Lock_img"
      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                      <Inputfield
                            label={
                              "Current Lock"
                            }
                            type={displaylock?.main_lock ? "text" : "password"}
                            //fieldname={"current_lock"}
                            value={locklogs[locklogs?.length - 1]?.current_lock}
                            labelprops={{ shrink: true }}
                            //onchange={this.changeHandler}
                            inputprops={{
                              readOnly : true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={()=>handelview("main_lock")}
                                    aria-label="toggle password visibility"
                                    edge="end"
                                  >
                                    {displaylock?.main_lock ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            
                            }}
                            helper={
                              <Link href = {`/#/bag-details/${tag_number}`}>
                                Edit
                              </Link>
                             
                            }
                            size="small"
                          />
                      </CardContent>
                      
                     
                      </Box>
                    
                      </Card>
                      
                    </div>
                  </> : <div style={{display:"flex",marginTop:"5rem",textAlign:"center",justifyContent:"center"}}>
                    Bag lock history are not available
                  </div>
                }

           </div>
       {
        locklogs?.length ? <>
            <div style={{display:"flex",flexDirection:"column",marginTop:"2rem"}} >
            {
                locklogs?.slice().reverse().slice(0, 3)?.map((e,index)=>{
                    return (
                        <>
                         <Lockcards item={e} index={index} />
                        </>
                    )
                })
            }
          
          
           </div>
        </> : null
       }
          </div>
          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
      </div>
    </>
  }
    </>
  )
}

export default Lockmanagement
